import React from "react";
import DefaultLayout from "../../components/DefaultLayout";
import Hero from "../../components/bricks/Hero";
import FeatureOverview from "../../components/FeatureOverview";
import {themeBright} from "../../page-styles/landingpage.css";
import ThemedSingleFeature from "../../components/bricks/ThemedSingleFeature";
import ThemeBox from "../../components/bricks/ThemeBox";

const Features = ({location}) => (
  <DefaultLayout
    title="Milestones"
    description="Plan for your release – one milestone at a time."
    location={location}
  >
    <Hero size="sm" title="Milestones" />
    <ThemeBox theme={themeBright} withBorder>
      <ThemedSingleFeature childAsDescription img="milestones-1.png">
        Setting and managing milestones is done in the most natural way possible on Codecks. Open
        the milestone calendar from your milestone tab, click on a date and you're done! Once
        created, you can drag and drop it to change the date.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Focus on specific milestones"
        img="milestones-2.png"
        imgRight
      >
        You can pin a milestone in the header to make sure you never loose sight of your goal. By
        default, the next upcoming milestone will always be displayed.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Apply milestones to cards and decks"
        img="milestones-3.png"
      >
        Milestones can be attached to a card or an entire deck. This enables you to order by or
        search for cards by milestone. More importantly, it enables you to switch to the milestone
        tab in order to work with cards in the context of a specific milestone.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Answer the question of what to work on next."
        img="milestones-4.png"
        imgRight
      >
        One of the advantages of the milestone tab is that it works much like your hand and deck
        library, enabling you to search and order your cards as you see fit. You can also navigate
        the timeline to see what is coming next, as well as add a new milestone directly.
      </ThemedSingleFeature>
    </ThemeBox>
    <FeatureOverview />
  </DefaultLayout>
);

export default Features;
